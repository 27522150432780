/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from '@unform/web';
import { IoMdClose } from 'react-icons/io';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';

import { AxiosResponse } from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal, Loading } from './styles';
import Input from '../Input';

import safe from '~/assets/defaults/safe_secure.svg';
import money from '~/assets/defaults/money_back.svg';
import privacy from '~/assets/defaults/privacy_proteced.svg';
import check from '~/assets/icons/check-payment.svg';
import checkPlus from '~/assets/icons/check-plus.svg';
import swalError from '~/utils/swalError';
import { formatPrice } from '~/utils/format';

export interface IOption {
  price: number;
  selected?: boolean;
  name?: string;
  nameSummary?: string;
  secondary?: boolean;
  src?: string;
  installments?: number;
  period?: number;
  type?: 'lifetime' | 'subscription';
  interval?: 'day' | 'month' | 'week' | 'year';
  productSlug?: string;
  unSelectable?: boolean;
  trial_period_days?: number;
  trial_price?: number;
}

interface IPayment {
  show: boolean;
  onHide(): void;
  productName: string;
  btnText: string;
  onSuccessfullyPaid(data: any): void;
  options: IOption[];
}

interface IStripeComponent {
  show: boolean;
  onHide(): void;
  productName: string;
  btnText: string;
  onSuccessfullyPaid(data: any): void;
  options: IOption[];
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

interface IParams {
  reference?: string;
  origin?: string;
}

interface ILocation {
  from: Location;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const StripeComponent: React.FC<IStripeComponent> = ({
  show,
  onHide,
  productName,
  btnText,
  onSuccessfullyPaid,
  options: optionsData,
}) => {
  const location = useLocation<ILocation>();
  const params = useParams<IParams>();
  const stripe = useStripe();
  const elements = useElements() as any;
  const formRef = useRef<FormHandles>(null);
  const [user, setUser] = useState<any>({});
  const [step, setStep] = useState(1);
  const [value, setValue] = useState<number[]>([]);
  const [optionSelected, setOptionSelected] = useState<IOption | undefined>(
    undefined
  );
  const [reference, setReference] = useState('');
  const [creditCard, setCreditCard] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionsSecondary, setOptionsSecondary] = useState<IOption[]>([]);

  const [selectedPrices, setSelectedPrices] = useState<number[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // const downsellPage = location.pathname.includes('ds');
  const downsellPage = location.pathname.includes('ds-2');
  const blackFridayPage = location.pathname.includes('blackfriday');

  useEffect(() => {
    const optionSelectedData = optionsData.find(
      (option) => option.selected && !option.secondary
    );
    if (optionSelectedData) {
      setOptionSelected(optionSelectedData);
    }

    setOptions(optionsData.filter((option) => !option.secondary));
    setOptionsSecondary(optionsData.filter((option) => option.secondary));
  }, [optionsData]);

  useEffect(() => {
    if (params.reference) {
      localStorage.setItem('@AutoAffiliate:reference', params.reference);
      setReference(params.reference);
    } else {
      const referenceData = localStorage.getItem('@AutoAffiliate:reference');
      if (referenceData) {
        setReference(referenceData);
      }
    }
  }, [params.reference]);

  useEffect(() => {
    const creditCardData = localStorage.getItem(
      '@AutoAffiliate:creditCardData'
    );

    if (creditCardData) {
      setCreditCard(JSON.parse(creditCardData));
      setStep(3);
    }
  }, []);

  useEffect(() => {
    const userDataString = localStorage.getItem('@AutoAffiliate:userData');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      api
        .get(`users/admin/${userData.user_id || userData.id}`)
        .then((response) => {
          if (response.data.name) {
            const nameParts = response.data.name.split(' ');
            const data = {
              id: response.data.id,
              firstName: nameParts[0],
              lastName: nameParts[nameParts.length - 1],
              email: response.data.email,
              phone: response.data.phone,
              // country: response.data.address.country,
              // state: response.data.address.state,
              // city: response.data.address.city,
              // zipCode: response.data.address.zip_code,
              // street: response.data.address.street,
              // number: response.data.address.number,
              // neighborhood: response.data.address.neighborhood,
              // complement: response.data.address.complement,
            };
            setUser(data);
          } else {
            setUser(response.data);
          }
        });
    }
  }, [show]);

  useEffect(() => {
    const newPrices = optionsSecondary
      .filter((optionData) => optionData.selected)
      .map((optionData) => optionData.price);

    setTotalPrice(newPrices.reduce((total, price) => total + price, 0));
  }, [optionsSecondary]);

  const handleSetStep = useCallback(() => {
    switch (step) {
      case 1:
        setStep(step + 1);

        break;
      case 2:
        setStep(step + 1);

        break;

      default:
        setStep(1);
        break;
    }
  }, [step]);

  const handleSubmitPersonalDetails = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          firstName: yup.string().required('First name is required'),
          lastName: yup.string().required('Last name is required'),
          email: yup
            .string()
            .email('Enter a valid email address')
            .required('Email is required'),
          phone: yup.string().required('Phone is required'),
          // country: yup.string().required('Country is required'),
          // state: yup.string().required('State is required'),
          // city: yup.string().required('City is required'),
          // zipCode: yup.string().required('Zip code is required'),
          // street: yup.string().required('Street is required'),
          // number: yup.string().required('Number is required'),
        });

        await schema.validate(data, { abortEarly: false });
        setLoading(true);
        const formData = {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          phone: data.phone,
        };

        let response: AxiosResponse;
        if (user.id) {
          response = await api.put(`users/${user.id}`, formData);
          localStorage.setItem(
            '@AutoAffiliate:userData',
            JSON.stringify(response.data)
          );
        } else {
          let originData = '';
          if (params.origin) {
            switch (params.origin) {
              case 'rf':
                originData = 'AI Bot Facebook, ';
                break;
              case 'uf':
                originData = 'Facebook, ';
                break;
              case 'ue':
                originData = 'User Email, ';
                break;
              case 'ux':
                originData = 'User Twitter (X), ';
                break;
              case 'uw':
                originData = 'User Whatsapp, ';
                break;
              case 'utt':
                originData = 'User Tiktok, ';
                break;
              case 'ul':
                originData = 'User Linkedin, ';
                break;
              case 'ut':
                originData = 'User Traffic, ';
                break;
              case 'aat':
                originData = 'AutoAffiliate Traffic, ';
                break;

              default:
                originData = '';
                break;
            }
          }

          const path = location.pathname.split('/');
          response = await api.post(
            `users`,
            {
              ...formData,
              reference: params.reference,
            },
            {
              params: {
                origin: `${originData}${path[1]}`,
              },
            }
          );
          localStorage.setItem(
            '@AutoAffiliate:userData',
            JSON.stringify(response.data)
          );
        }

        setUser(response.data);
        handleSetStep();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [handleSetStep, location.pathname, params.origin, params.reference, user.id]
  );

  const handleSubmitPaymentMethod = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true);
        const result = await stripe?.createToken(
          elements.getElement(CardElement)
        );

        const response = await api.post('payments/payment-methods', {
          type: 'card',
          token: result?.token?.id,
          customer_id: user.customer_id,
        });

        const formData = {
          id: response.data.id,
          user_id: user.id,
          name: result?.token?.card?.name,
          number: result?.token?.card?.last4,
          expirity: `${result?.token?.card?.exp_month}/${result?.token?.card?.exp_year}`,
          brand: result?.token?.card?.brand,
          primary_card: false,
        };

        api.post('credit-cards', formData);

        localStorage.setItem(
          '@AutoAffiliate:creditCardData',
          JSON.stringify(formData)
        );

        setCreditCard(formData);
        handleSetStep();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        swalError({
          message:
            'Looks like what you were trying to do didn’t work, please try again.',
          textButton: 'Try Again',
        });
      }
    },
    [elements, handleSetStep, stripe, user]
  );

  const handleClickConfirmPayment = useCallback(async () => {
    try {
      const optionsSelected = [
        optionSelected,
        ...optionsSecondary.filter((option) => option.selected),
      ];
      if (optionsSelected.length > 0) {
        setLoading(true);
        const responsesData = await new Promise<any>((resolve) => {
          const responses: AxiosResponse[] = [];
          optionsSelected.forEach(async (option, index) => {
            const responseProduct = await api.get(
              `products/${option?.productSlug}`
            );
            const response = await api.post(
              `payments/credit-cards/${user.id}`,
              {
                payment_method: creditCard.id,
                price: option?.price,
                product_id: responseProduct.data.id,
                brand: creditCard.brand,
                number: creditCard.number,
                period: option?.period,
                installments: option?.installments,
                type: option?.type,
                interval: option?.interval,
                trial_period_days: option?.trial_period_days,
                trial_price: option?.trial_price,
              }
            );
            responses.push(response.data);
            if (optionsSelected.length === index + 1) {
              resolve(responses);
            }
          });
        });
        onSuccessfullyPaid(responsesData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swalError({
        message:
          'Looks like what you were trying to do didn’t work, please try again.',
        textButton: 'Try Again',
      });
    }
  }, [
    optionSelected,
    optionsSecondary,
    onSuccessfullyPaid,
    user.id,
    creditCard.id,
    creditCard.brand,
    creditCard.number,
  ]);

  const handleSelectProduct = useCallback(
    (option: IOption) => {
      const newOptions = options.map((optionData) => {
        let { selected } = optionData;
        if (optionData.name === option.name) {
          selected =
            optionData.src === option.src &&
            (option.unSelectable || !optionData.selected);
        }

        return {
          ...optionData,
          selected,
        };
      });

      const hasOptionSelected = newOptions.find(
        (optionData) => optionData.selected
      );

      if (!hasOptionSelected) {
        setOptionsSecondary((state) =>
          state.map((optionData) => ({ ...optionData, selected: false }))
        );
      }

      setOptions(newOptions);

      if (!option.secondary) {
        setOptionSelected((state) =>
          state && state.src === option.src && !option.unSelectable
            ? undefined
            : option
        );
      }
    },
    [options]
  );

  const handleSelectSecondaryProduct = useCallback(
    (option: IOption) => {
      const optionIndex = optionsSecondary.findIndex(
        (optionData) => optionData.src === option.src
      );

      const newOptions = optionsSecondary.map((optionData, index) => {
        let { selected } = optionData;
        if (optionData.name === option.name) {
          selected =
            optionData.src === option.src &&
            (option.unSelectable || !optionData.selected);
        }

        if (optionsSecondary[optionIndex].selected && index > optionIndex) {
          selected = false;
        }

        return {
          ...optionData,
          selected,
        };
      });

      setOptionsSecondary(newOptions);

      const newPrices = newOptions
        .filter((optionData) => optionData.selected)
        .map((optionData) => optionData.price);

      setTotalPrice(newPrices.reduce((total, price) => total + price, 0));
    },

    [optionsSecondary]
  );

  return (
    <Container>
      <Modal
        className="modal-link px-0"
        centered
        size="xl"
        show={show}
        onHide={onHide}
      >
        <Modal.Header className="d-block border-0 pb-0 pt-4 px-4">
          <div className="w-100 text-end">
            <button
              type="button"
              className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
              onClick={onHide}
            >
              <IoMdClose color="#151517" size={24} />
            </button>
          </div>
          <div className="container">
            <div className="row mb-5 pt-2 justify-content-center">
              <div
                className={`${step === 1 ? 'selected-step' : 'step'} ${
                  step > 1 ? 'active-step' : ''
                } px-0 col-3 col-lg-2 text-center`}
                // onClick={() => setStep(1)}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">1</span>
                </div>
                <p>Personal Details</p>
              </div>
              <div className="col-1 pt-2">
                <hr className={step > 1 ? 'active-hr' : ''} />
              </div>
              <div
                className={`${step === 2 ? 'selected-step' : 'step'} ${
                  step > 2 ? 'active-step' : ''
                } px-0 col-3 col-lg-2 text-center`}
                // onClick={() => setStep(2)}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">2</span>
                </div>
                <p>Payment Method</p>
              </div>
              <div className="col-1 pt-2">
                <hr className={step > 2 ? 'active-hr' : ''} />
              </div>
              <div
                className={`${
                  step === 3 ? 'selected-step' : 'step'
                } px-0 col-3 col-lg-2 text-center`}
                // onClick={() => setStep(3)}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">3</span>
                </div>
                <p>Confirm</p>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="container py-0 px-4 px-lg-5">
          <Form
            ref={formRef}
            onSubmit={handleSubmitPersonalDetails}
            initialData={user}
            className={step === 1 ? 'd-block' : 'd-none'}
          >
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="font-weight-500 mb-4">
                  Please fill out the information below:
                </h2>
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="name">First Name</label>
                    <Input
                      name="firstName"
                      id="firstName"
                      className="bg-input"
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="name">Last Name</label>
                    <Input name="lastName" id="lastName" className="bg-input" />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="email">Email Address</label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-input"
                    />
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="phone">Phone Number</label>
                    <Input name="phone" id="phone" className="bg-input" />
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <button
                  type="submit"
                  className={`${
                    loading && 'pe-none'
                  } w-100 d-flex justify-content-center border-0 btn-black font-weight-600 text-white`}
                >
                  {loading ? <Loading className="spinner" /> : 'Next'}
                </button>
              </div>
            </div>
          </Form>
          <form
            onSubmit={handleSubmitPaymentMethod}
            className={step === 2 ? 'd-block' : 'd-none'}
          >
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="mb-4">Customer Checkout</h2>
              </div>
              <div className="col-lg-10">
                <div className="row items">
                  <div className="col-12 mb-5">
                    <CardElement
                      options={{
                        hidePostalCode: true,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <button
                  type="submit"
                  className={`${
                    loading && 'pe-none'
                  } w-100 d-flex justify-content-center border-0 btn-black font-weight-600 text-white`}
                >
                  {loading ? <Loading className="spinner" /> : 'Next'}
                </button>
              </div>
            </div>
          </form>
          <div
            className={`row justify-content-center items ${
              step === 3 ? '' : 'd-none'
            }`}
          >
            <div className={`${downsellPage ? 'col-lg-11' : 'col-lg-10'} mb-5`}>
              <div className="row">
                <div className="col-9 mb-1">
                  <h3 className="font-weight-600">Item</h3>
                </div>
                <div className="col-3 d-flex justify-content-end mb-1">
                  <h3 className="font-weight-600 text-end">Price</h3>
                </div>

                <div className="col-7 mb-4">
                  <span
                    className="font-weight-500"
                    dangerouslySetInnerHTML={{ __html: productName }}
                  />
                </div>
                <div className="col-5 text-end mb-4">
                  <span className="fw-semibold text-end total">
                    {optionSelected
                      ? ` ${
                          optionSelected.type === 'lifetime'
                            ? `${formatPrice(
                                optionSelected.price + totalPrice
                              )}`
                            : `$1.00 Now - $${(
                                optionSelected.price + totalPrice
                              )?.toFixed(2)} in 7 days`
                        }`
                      : '$0.00'}
                  </span>
                  {(downsellPage ||
                    (blackFridayPage && optionSelected?.period)) && (
                    <span className="months">
                      in {optionSelected?.period} month
                      {optionSelected?.period !== undefined &&
                        optionSelected?.period > 1 &&
                        's'}{' '}
                      ${(optionSelected?.price || 0).toFixed(2)}
                    </span>
                  )}
                </div>

                {options.map((option, index) => (
                  <button
                    key={index.toString()}
                    type="button"
                    className={`${
                      option.selected
                        ? 'opacity-100'
                        : `${!option.secondary ? 'opacity-50 grayscale ' : ''} `
                    } col-12 border-0 px-2 mb-3 bg-transparent position-relative`}
                    onClick={() => {
                      handleSelectProduct(option);
                      setValue([option.price]);
                    }}
                  >
                    {option.selected && (
                      <img src={check} alt="Check" className="check-position" />
                    )}
                    {!option.selected && option.secondary && (
                      <img
                        src={checkPlus}
                        alt="Check"
                        className="check-position"
                      />
                    )}
                    <img
                      src={option.src}
                      alt="checkout"
                      className={`bd-blue w-100 `}
                    />
                  </button>
                ))}

                {optionSelected &&
                  optionsSecondary.map((option, index) => (
                    <Fragment key={index.toString()}>
                      {(index === 0 ||
                        (optionsSecondary[index - 1] &&
                          optionsSecondary[index - 1].selected)) && (
                        <button
                          type="button"
                          className={`${
                            option.selected
                              ? 'opacity-100'
                              : `${
                                  !option.secondary
                                    ? 'opacity-50 grayscale '
                                    : ''
                                } `
                          } col-12 border-0 ${
                            !option.secondary ? 'px-0' : 'px-2 mb-3'
                          }   bg-transparent position-relative`}
                          onClick={() => {
                            handleSelectSecondaryProduct(option);
                          }}
                        >
                          {option.selected && (
                            <img
                              src={check}
                              alt="Check"
                              className="check-position"
                            />
                          )}
                          {!option.selected && option.secondary && (
                            <img
                              src={checkPlus}
                              alt="Check"
                              className="check-position"
                            />
                          )}
                          <img
                            src={option.src}
                            alt="checkout"
                            className={`${
                              option.secondary
                                ? `${option.selected ? 'bd-blue' : 'bd-dashed'}`
                                : ''
                            } w-100 `}
                          />
                        </button>
                      )}
                    </Fragment>
                  ))}
                <div className="col-9 mt-4 mb-3">
                  <h3 className="font-weight-600">Item</h3>
                </div>
                <div className="col-3 d-flex justify-content-end mt-4 mb-3">
                  <h3 className="font-weight-600 text-end">Price</h3>
                </div>

                <div className="col-9 mb-3">
                  <span
                    className="font-weight-500"
                    dangerouslySetInnerHTML={{ __html: productName }}
                  />
                </div>
                <div className="col-3 text-end  mb-3">
                  <span className="font-weight-500 text-end price">
                    ${(optionSelected?.price || 0).toFixed(2)}
                    {optionSelected?.interval === 'month' && '/Mo'}
                  </span>
                </div>
                {optionSelected &&
                  optionsSecondary.map((option, index) => (
                    <Fragment key={index.toString()}>
                      {optionsSecondary[0].selected &&
                        optionsSecondary[index] &&
                        optionsSecondary[index].selected && (
                          <>
                            <div className="col-9 mb-3">
                              <span className="font-weight-500">
                                {optionsSecondary[index].nameSummary}
                              </span>
                            </div>
                            <div className="col-3 text-end mb-3">
                              <span className="font-weight-500 text-end price">
                                ${(option.price || 0).toFixed(2)}
                                {option.interval === 'month' && '/Mo'}
                              </span>
                            </div>
                          </>
                        )}
                    </Fragment>
                  ))}
                <hr className="opacity-100" />
                <div className="col-7 mb-1">
                  <h3 className="font-weight-600">Order Total:</h3>
                </div>
                <div className="col-5 ps-0 ps-sm-3 d-flex justify-content-end mb-1">
                  <h3 className="font-weight-600 text-end total">
                    {optionSelected
                      ? ` ${
                          optionSelected.type === 'lifetime'
                            ? `${formatPrice(
                                optionSelected.price + totalPrice
                              )}`
                            : `$1.00 Now - $${(
                                optionSelected.price + totalPrice
                              )?.toFixed(2)} in 7 days`
                        }`
                      : '$0.00'}
                  </h3>
                </div>
              </div>
            </div>

            <div className={`${downsellPage ? 'col-lg-11' : 'col-lg-10'}`}>
              <button
                type="button"
                className={`${loading && 'pe-none'} ${
                  !optionSelected ? 'opacity-50 pe-none' : ''
                } w-100 d-flex justify-content-center border-0 btn-black font-weight-600 text-white`}
                onClick={handleClickConfirmPayment}
              >
                {loading ? (
                  <span className="d-flex align-items-center font-weight-600 text-white">
                    <Loading className="d-block me-3 spinner" />
                    Processing Payment, Please Hold...
                  </span>
                ) : (
                  btnText
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="container border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-lg-10 my-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-sm-6 col-lg-4 text-center">
                  <img src={safe} alt="GUARANTEED SAFE & SECURE CHECKOUT" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center mt-4 mt-sm-0">
                  <img src={money} alt="MONEY-BACK GUARANTEE" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center my-4 my-lg-0">
                  <img src={privacy} alt="PRIVACY PROTECED" />
                </div>
                <div className="col-12 terms mt-1 pb-5">
                  <h4 className="mb-1">
                    <b>Terms of Sale:</b>
                  </h4>
                  <p className="font-weight-200 mb-0">
                    Your purchase will appear on your bank statement under the
                    name "<b>SmartTech Labs, Inc.</b>" The geographic location
                    of your computer has been determined and recorded. All
                    attempts at fraud will be prosecuted. Information submitted
                    in conjunction with this order is handled within the
                    constraints of our privacy policy. Your name, phone, and
                    email address may be provided to 3rd parties completion of
                    this purchase. You agree to receive marketing emails from
                    our company. By purchasing you agree to allow us to send
                    information to you via phone and SMS messaging. Message &
                    Data rates may apply. Reply stop to be removed. We will
                    instantly send your login information to our online training
                    program once your purchase is completed. Please review our
                    terms and conditions for the 30 day MBG refund policy on
                    this purchase.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const Payment: React.FC<IPayment> = ({
  show,
  onHide,
  productName,
  btnText,
  onSuccessfullyPaid,
  options,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeComponent
        show={show}
        onHide={onHide}
        productName={productName}
        options={options}
        btnText={btnText}
        onSuccessfullyPaid={onSuccessfullyPaid}
      />
    </Elements>
  );
};

export default Payment;
