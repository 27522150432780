/* eslint-disable no-console */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationsErrors from '~/utils/getValidationsErrors';

import { Button, Modal, ModalWait } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import closeIcon from '~/assets/icons/close.svg';
import checkWhite from '~/assets/icons/check-white.svg';
import checkBlack from '~/assets/icons/check-black.svg';
import checkGradient from '~/assets/icons/check-gradient.svg';
import circleCheck from '~/assets/icons/circle-check-black.svg';
import thumbHand from '~/assets/defaults/thumb-hand.png';
import Payment from '../Payment';
import Player from '../Player';

interface IBtn {
  className?: string;
  showChoice: boolean;
  onClickShow(): void;
}

const ModalSaveMoney: React.FC<IBtn> = ({
  className,
  showChoice,
  onClickShow,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showModalNo, setShowModalNo] = useState(false);
  const [priceSelected, setPriceSelected] = useState('');

  const handleSuccessfullyPaid = useCallback(
    async (products) => {
      console.log(products);
      const userData = localStorage.getItem('@AutoAffiliate:userData');

      const vipUpgradeSelected = products.find(
        (product: any) =>
          product.order.product_id === '16ea3f78-caaa-4af0-afcd-503cbdcb465e'
      );

      if (userData) {
        const user = JSON.parse(userData);
        if (vipUpgradeSelected) {
          await api.post(`users/welcome/${user.id}`);
          const response = await api.post('users/sessions/set-data', {
            user_id: user.id,
            data: 'auto-login',
          });
          console.log(response);

          window.location.href = `https://app.autoaffiliate.ai/check-login/${response.data.token}`;
        } else {
          history.push(`${process.env.PUBLIC_URL}/vip`, {
            products,
          });
        }
      }
    },
    [history]
  );

  const handleSelectPrice = useCallback((productPrice) => {
    setPriceSelected(productPrice);
    setShow(true);
  }, []);

  return (
    <>
      <Modal
        className="modal-link px-0"
        centered
        size="xl"
        show={showChoice}
        onHide={onClickShow}
      >
        <Modal.Header className="border-0 pb-sm-0 pt-4 pt-sm-5 px-4 px-sm-5 justify-content-end" />
        <Modal.Body className="container py-0 px-3 px-sm-5">
          <div className="row">
            <div className="col-12 my-4 my-lg-0">
              <h2 className="">Save Money and Get More?</h2>
            </div>

            <div className="col-12 mb-4 mb-lg-0">
              <p className="mb-5 h2-description">
                Would you like to have lifetime unlimited access without ever
                paying a monthly fee and receive a special bonus?
              </p>
            </div>
          </div>
          <div className="row mb-5 justify-content-around">
            <div className="col-lg-7">
              <div className="bg-blue text-white p-5 p-lg-4 p-xl-5">
                <u className="d-flex justify-content-end text-end mb-xl-n4">
                  RECOMMENDED FOR <br />
                  MAXIMUM VALUE
                </u>
                <h3 className="h2 h1-sm text-white fw-bold">$97 One-Time</h3>
                <p className="h6 fw-normal text-white">
                  Lifetime Access Plan - Never pay a monthly fee
                </p>
                <div className="row mt-5">
                  <div className="col-xl-7">
                    <div className="d-flex mb-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">No Monthly Fees - Ever!</p>
                    </div>
                    <div className="d-flex mb-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">
                        Success Mindset training
                      </p>
                    </div>
                    <div className="d-flex mb-4 mb-xl-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">Special bonuses</p>
                    </div>
                    <div className="d-flex mb-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">
                        Exclusive Masterclass workbook
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <div className="d-flex mb-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">
                        Lifetime access to the Masterclass
                      </p>
                    </div>
                    <div className="d-flex mb-3 align-items-center">
                      <img src={checkWhite} alt="Check icon" className="me-3" />
                      <p className="text-white mb-0">
                        10+ Online business courses bonus
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3 mt-lg-0">
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectPrice('97');
                      }}
                      className="w-100 border-0 pad-button fw-bold mt-5 text-black bg-white"
                    >
                      GET LIFETIME ACCESS NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0 ps-xl-5">
              <div className="bd-lg-black h-100 p-5 p-lg-4 p-xl-5 d-flex flex-column">
                <h3 className="h2 h1-sm text-black fw-bold">$7 Per Month</h3>
                <p className="text-mob-gray">Join now for only $7 per month</p>
                <div className="d-flex pb-2  mb-4 align-items-start mt-4 mt-xxl-5">
                  <img src={checkBlack} alt="Check icon" className="me-2" />
                  <p className="mb-0">Monthly access to the Masterclass</p>
                </div>
                <div className="d-flex  pb-2 mb-4 align-items-start">
                  <img src={checkBlack} alt="Check icon" className="me-2" />
                  <p className="mb-0">Exclusive Masterclass workbook</p>
                </div>
                <div className="d-flex mb-0 align-items-start">
                  <img src={checkBlack} alt="Check icon" className="me-2" />
                  <p className="mb-0">Success Mindset training</p>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowModalNo(true);
                  }}
                  className="w-100 border-0 pad-button fw-bold text-white bg-plan mt-5 mt-lg-auto"
                >
                  Continue with this plan
                </button>
              </div>
            </div>
          </div>
          <div className="row px-3 px-lg-0 pt-xl-5 justify-content-center">
            <div className="col-lg-11 py-5 py-lg-0 bd-mobile">
              <div className="row bg-gray padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 fw-bold">
                    10+ Online business courses bonus
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $1,997 Value
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 fw-bold">
                    Lifetime access to the Masterclass
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $397 Value
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row bg-gray padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 fw-bold">
                    Exclusive Masterclass workbook
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $47 Value
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 col-xl-2 ms-auto text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 fw-bold">
                    Success Mindset training
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $297 Value
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block" />
              </div>
              <div className="row bg-gray padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 fw-bold">
                    Special bonuses
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $97 Value
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 col-xl-2 ms-auto text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-lg-end mt-5 mb-4">
            <div className="col-lg-10 text-center text-xl-left col-xl-7">
              <button
                type="button"
                onClick={() => {
                  handleSelectPrice('97');
                }}
                className="btn-blue border-0 text-white me-lg-5"
              >
                GET LIFETIME ACCESS NOW
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowModalNo(true);
                }}
                className="btn-continue border-0 bg-transparent fw-bold mt-4 mt-xl-0"
              >
                Continue with this plan
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
      <ModalWait
        className="modal-verify-email"
        centered
        size="xl"
        show={showModalNo}
      >
        <Modal.Header className="border-0 justify-content-center pt-0">
          {' '}
        </Modal.Header>
        <Modal.Body className="px-lg-5 mx-lg-4">
          <h2 className="text-center mb-4">
            <b className="text-color-red">🚨 Please Wait!</b>{' '}
            <b>Are You Sure You Want to Miss</b> This Opportunity? 🚨
          </h2>
          <div className="px-lg-5">
            <Player
              id="video-modal"
              src="https://cdn.autoaffiliate.ai/videos/AA-2.0-Upsell-v2.mp4"
              thumbnail={thumbHand}
              autoPlay
            />
          </div>
          <h3 className="text-center my-3 py-2">
            We noticed{' '}
            <span className="fw-semibold fst-italic text-color-blue">
              you decided miss out on the chance to get the Lifetime Access
            </span>{' '}
            to our Masterclass and all the incredible bonuses that comes with
            it.{' '}
          </h3>
          <h3 className="text-center mb-3 pb-2 h3 font-daughter text-color-red px-lg-4">
            We truly believe that our members have{' '}
            <span className="text-white bg-danger-span">
              75% higher chances of success
            </span>{' '}
            if they have the entire package{' '}
          </h3>

          <h4 className="h2 pb-2 mb-3 font-libre text-center">
            <span className="fst-italic text-color-blue">We understand</span>{' '}
            that the financial investment might be a concern for you right now,
            especially since we’ve just met...
          </h4>
          <span className="h1 mb-3 pb-2 d-block text-center font-daughter text-color-blue">
            So, here's what we're going to do:
          </span>
          <p className="mb-3 pb-2">
            <span className="fw-semibold text-color-blue">
              We want you to have the best
            </span>
            , so we’re offering you{' '}
            <span className="fw-semibold">
              another chance to get Lifetime Access to the Masterclass,
            </span>{' '}
            all the incredible bonuses, and unlock the full potential of your
            new online business{' '}
            <span className="h2 font-daughter text-color-blue">but...</span>
          </p>

          <span className="h3 d-block text-center font-libre">
            <b>Only make one</b> payment of just{' '}
            <b className="text-color-blue">$48.50 today...</b>
          </span>
          <span className="h3 d-block text-center font-libre mb-3 pb-2">
            ...and the{' '}
            <span className="fst-italic text-color-blue">
              next $48.50 only 30 days from now.
            </span>
          </span>
          <h4 className="h2 font-libre text-center fw-bold mt-4 mb-0 text-color-blue">
            Don't let this opportunity slip away!
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0 px-lg-5 pb-4 pb-lg-5">
          <button
            type="button"
            onClick={() => {
              handleSelectPrice('48.5');
              setShowModalNo(false);
            }}
            className="w-100 btn-yes-modal mx-lg-4 mb-4"
          >
            Yes, I want to reconsider and get lifetime access to the Masterclass
            and all the awesome bonuses for $48.50 today and the next $48.50
            only 30 days from now
          </button>
          <button
            type="button"
            onClick={() => {
              handleSelectPrice('7');
              setShowModalNo(false);
            }}
            className="w-100 btn-no-modal px-5 mx-lg-4 mb-4"
          >
            No Thanks, I want to try the $7 per month plan instead
          </button>
        </Modal.Footer>
      </ModalWait>
      <Payment
        show={show}
        onHide={() => setShow(false)}
        productName="The Ultimate Online Business Masterclass"
        btnText="Yes, Process My Order Now!"
        onSuccessfullyPaid={handleSuccessfullyPaid}
        options={[
          {
            price: 7,
            src: 'https://cdn.autoaffiliate.ai/files/masterclass-7-new.png',
            period: 1,
            type: 'subscription',
            interval: 'month',
            name: 'masterclass',
            selected: priceSelected === '7',
            productSlug: 'masterclass',
          },
          {
            price: priceSelected === '97' || priceSelected === '7' ? 97 : 48.5,
            src: `${
              priceSelected === '97' || priceSelected === '7'
                ? 'https://cdn.autoaffiliate.ai/thumbnails/masterclass-97.png'
                : 'https://cdn.autoaffiliate.ai/files/masterclass-48-5.png'
            }`,
            type: 'lifetime',
            name: 'masterclass',
            selected: priceSelected === '97' || priceSelected === '48.5',
            productSlug: 'masterclass',
            installments: priceSelected === '48.5' ? 2 : undefined,
            period: priceSelected === '48.5' ? 1 : undefined,
          },
          {
            price: 47,
            src: 'https://cdn.autoaffiliate.ai/files/become-affiliate.png',
            period: 1,
            type: 'subscription',
            interval: 'month',
            name: 'affiliate',
            secondary: true,
            nameSummary: 'Affiliate Program Community Access & AI Tools',
            productSlug: 'affiliate',
          },
          {
            price: 97,
            src: 'https://cdn.autoaffiliate.ai/files/upgrade-vip-97.png',
            type: 'lifetime',
            name: 'vip',
            secondary: true,
            nameSummary: 'VIP Affiliate Upgrade',
            productSlug: 'vip-upgrade',
          },
        ]}
      />
    </>
  );
};

export default ModalSaveMoney;
