import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FaExclamationCircle } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import {
  Container,
  Attention,
  Gpt,
  Current,
  Steps,
  Access,
  Dear,
} from './styles';
import Player from '~/components/Player';
import thumbFree from '~/assets/defaults/thumb-free.webp';
import whatsNext from '~/assets/defaults/whats-next.svg';
import quote from '~/assets/icons/quote-blue-thanks.svg';
import logo from '~/assets/logos/logo.svg';
import effortless from '~/assets/defaults/effortless.png';
import lamp from '~/assets/defaults/lamp.svg';
import check1 from '~/assets/defaults/check-1.svg';
import check2 from '~/assets/defaults/check-2.svg';
import check3 from '~/assets/defaults/check-3.svg';
import check4 from '~/assets/defaults/check-4.svg';
import check5 from '~/assets/defaults/check-5.svg';
import eye from '~/assets/defaults/eye.svg';
import star from '~/assets/defaults/star.svg';
import tryIt from '~/assets/defaults/try-it.svg';
import api from '~/services/api';
import Disclaimer from '~/components/Disclaimer';
import DisclaimerNew from '~/components/DisclaimerNew';
import Payment from '~/components/Payment';

const ThanksUltimateGuide: React.FC = () => {
  const history = useHistory();
  const [name, setName] = useState<string>();
  const [token, setToken] = useState('');
  const [show, setShow] = useState(false);
  const [play, setPlay] = useState(true);

  const userData = useMemo(() => {
    const data = localStorage.getItem('@AutoAffiliate:userData');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }, []);

  useEffect(() => {
    api
      .get(`users/admin/${userData?.user_id || userData?.id}`)
      .then((response) => {
        setToken(response.data.cache_token);
        setName(response.data.name);
      });
  }, [userData?.id, userData?.user_id]);

  const handleClickAccess = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_APP_URL}/auto-login/${token}?page=profile&page=my-purchases`;
  }, [token]);

  const handleSuccessfullyPaid = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/pe-welcome`);
  }, [history]);

  const btnVideo = document.getElementById('video') as HTMLVideoElement;
  const handleShowPayment = useCallback(() => {
    setShow(true);
    if (btnVideo && !show) {
      btnVideo.pause();
    }
  }, [btnVideo, show]);

  return (
    <Container className="overflow-hidden">
      <Attention className="bg-danger">
        <div className="container zoom">
          <div className="row">
            <div className="col zoom">
              <h2 className="h6 h5-xl fw-normal text-white mb-0 py-3 text-center">
                <FaExclamationCircle />
                <b className="ms-3">DO NOT CLOSE THIS PAGE YET!: </b>
                Please <b>Read Everything on This Page Carefully</b> To Ensure
                You Get Access To Your Guide
              </h2>
            </div>
          </div>
        </div>
      </Attention>
      <Gpt>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-12 px-lg-0">
              <h1 className="text-center mt-4 mt-sm-5 mb-3">
                🎉 YOU DID IT!! 🎉
                <br />
                <span className="d-block pt-3 pt-sm-4">
                  Your Ultimate Guide to Creating Magnetic Social Content{' '}
                  <b>is on its way!</b>
                </span>
              </h1>
              <h2 className="text-center mb-3 mb-sm-4">
                <span className="mark-text">
                  <span>Watch the video below</span> for important next step
                  instructions:
                </span>
              </h2>
            </div>

            <div className="col-md-9 mb-4 position-relative">
              <Player
                id="video"
                buttonId="video-button"
                src="https://cdn.autoaffiliate.ai/videos/thanks-ultimate-guide.mp4"
                thumbnail={thumbFree}
                autoPlay
              />
              <img
                src={whatsNext}
                alt="Whats Next"
                className="d-none d-md-block whats-next"
              />
            </div>
            <div className="col-lg-10">
              <button
                type="button"
                className="px-lg-5 mx-auto btn-yes w-100 mt-1"
                onClick={handleShowPayment}
              >
                YES! I WANT TO AUTOMATE MY SOCIAL CONTENT NOW!
              </button>
            </div>
          </div>
        </div>
      </Gpt>

      <Steps>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-lg-10 mb-4">
              <h2 className="my-5">
                Unlock{' '}
                <span className="position-relative">
                  <img
                    src={effortless}
                    alt="Effortless"
                    className="effortless"
                  />
                  Effortless
                </span>{' '}
                Social Media Success with{' '}
                <span className="gradient">PostEASE</span>
              </h2>
              <div className="d-flex align-items-start position-relative">
                <h3 className="mb-5 text-center">
                  <b>Imagine automating</b> your social media content creation
                  and posting with just a few clicks.
                </h3>
                <img src={lamp} alt="Lamp" className="lamp" />
              </div>
            </div>
            <div className="col-12 ">
              <h4 className="mb-4">With PostEASE, you can:</h4>
            </div>
            <div className="col-10 col-lg-12 ms-auto mx-lg-auto">
              <p>
                <img src={check1} alt="Check" className="check-1" />
                <span className="mark-text p-3">
                  <b>Automate Your Social Media:</b> Effortlessly create and
                  publish daily content.
                </span>
              </p>
              <p>
                <img src={check2} alt="Check" className="check-2" />
                <span className="mark-text p-3">
                  <b>Optimal Posting Schedule:</b> Advanced AI determines the
                  best times to post.
                </span>
              </p>
              <p>
                <img src={check3} alt="Check" className="check-3" />
                <span className="mark-text p-3">
                  <b>Multi-Platform Integration:</b> Extend your reach across
                  top social media platforms.
                </span>
              </p>
              <p>
                <img src={check4} alt="Check" className="check-4" />
                <span className="mark-text p-3">
                  <b>Consistency Across All Touchpoints:</b> Align your content
                  with your brand’s unique voice.
                </span>
              </p>
              <p>
                <img src={check5} alt="Check" className="check-5" />
                <span className="mark-text p-3">
                  <b>Effortless Viral Reels on Autopilot:</b> AI crafts
                  engaging, trend-driven reels for your brand.
                </span>
              </p>
            </div>
          </div>
        </div>
      </Steps>
      <Access>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="text-center pt-5">
                <h2 className="my-5 pb-5">
                  PostEASE Let’s You Automate{' '}
                  <b className="c-blue">EVERYTHING</b> From The Guide You Just
                  Got And <b className="c-blue">Much More...</b>
                </h2>
              </div>
            </div>
            <div className="col-lg-9">
              <h3 className="mb-4">
                <b className="c-blue">
                  It will save you SO MUCH TIME & Effort!
                </b>{' '}
                no learning curve, no trial & error no STRESS....{' '}
              </h3>
              <h4 className="mb-5 pb-5">
                You can literally set it and forget it !
              </h4>
            </div>
            <div className="col-12">
              <h2 className=" text-start ">
                <span className="h2-gradient position-relative">
                  Here is a Special Offer for You
                  <img src={eye} alt="Eye" className="eye" />
                </span>
              </h2>
              <p className="mt-5">
                Because <b>we appreciate you taking massive action</b> and
                getting our Ultimate Guide,{' '}
                <b className="fst-italic c-blue">
                  we want to give you a one-time chance to upgrade to PostEASE
                  now
                </b>{' '}
                and transform your social media management....
              </p>
              <h5 className="my-5 py-5">
                <span className="mark-text-lg position-relative">
                  <img src={star} alt="Star" className="star-1" />
                  For just $1 Dollar!
                  <img src={star} alt="Star" className="star-2" />
                </span>
              </h5>
            </div>
            <div className="col-lg-9">
              <h6 className="text-center">
                <b>That’s right!</b>{' '}
                <span className="fst-italic">Pick any of our plans</span>{' '}
                <b>
                  and{' '}
                  <span className="position-relative">
                    try it
                    <img src={tryIt} alt="Try it" className="try-it" />
                  </span>
                </b>
                <br />
                <b className="c-blue">RISK FREE for 7-Days</b>
              </h6>
            </div>
            <div className="col-lg-10">
              <button
                type="button"
                className="px-lg-5 mx-auto btn-yes w-100 mt-5"
                onClick={handleShowPayment}
              >
                YES! I WANT TO AUTOMATE MY SOCIAL CONTENT NOW!
              </button>
            </div>
          </div>
        </div>
      </Access>

      <Dear>
        <img src={logo} alt="Logo" className="logo" />
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-12 p-4 p-sm-5 my-4">
              <h2 className="mb-4 mb-sm-5">Dear {name ?? 'member'},</h2>
              <h3 className="mb-4 mb-sm-5">
                We’re absolutely thrilled to welcome you to our community!{' '}
              </h3>
              <p className="mb-4 mb-sm-5">
                By downloading "Your Ultimate Guide to Creating Magnetic Social
                Content," you’ve taken the first step towards transforming your
                social media strategy. Our commitment to your success is
                unwavering, and we're here to support you every step of the way.
              </p>

              <p className="mb-4 mb-sm-5">
                Imagine automating your social media content and posting with
                ease. That's exactly what PostEASE can do for you. As you
                explore the guide, envision how much simpler and more effective
                your content creation can become with the right tools at your
                disposal.
              </p>
              <p className="mb-4 mb-sm-5">
                Your ambition is now backed by our expertise and resources.
                Together, we can turn your goals into tangible achievements.
                Let's embark on this exciting journey with confidence and a
                shared vision for what's possible.
              </p>
              <p className="mb-4 mb-sm-5">
                If you have any questions or need assistance, our support team
                is ready to help. We’re here to ensure your success.
              </p>
              <p className="mb-4 mb-sm-5">With warm regards,</p>
              <h4>- The AutoAffiliate Team</h4>
            </div>
          </div>
        </div>
      </Dear>

      <Current>
        <div className="container zoom">
          <div className="row justify-content-center">
            <div className="col-sm-11 my-5 py-5">
              <DisclaimerNew />
            </div>
          </div>
        </div>
      </Current>
      <Payment
        show={show}
        onHide={() => setShow(false)}
        productName="PostEASE - Social Media Content Automation"
        btnText="Yes, Process My Order!"
        onSuccessfullyPaid={handleSuccessfullyPaid}
        options={[
          {
            price: 1497,
            src: 'https://cdn.autoaffiliate.ai/files/postease-1497.png',
            productSlug: 'postease',
            nameSummary:
              'PostEASE - Social Media Content Automation - Lifetime',
            type: 'lifetime',
            selected: true,
          },
          {
            price: 997,
            src: 'https://cdn.autoaffiliate.ai/files/postease-997.png',
            productSlug: 'postease',
            nameSummary: 'PostEASE - Social Media Content Automation - Yearly',
            interval: 'year',
            period: 1,
            type: 'subscription',
            trial_period_days: 7,
            trial_price: 1,
          },

          {
            price: 147,
            src: 'https://cdn.autoaffiliate.ai/files/postease-147.png',
            productSlug: 'postease',
            nameSummary: 'PostEASE - Social Media Content Automation - Monthly',
            interval: 'month',
            period: 1,
            type: 'subscription',
            trial_period_days: 7,
            trial_price: 1,
          },
        ]}
      />
    </Container>
  );
};

export default ThanksUltimateGuide;
